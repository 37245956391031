import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { PROCEDURETYPE } from 'src/app/app.constants';

export const procedureValidator = (): ValidatorFn => {
  return (formGroup: UntypedFormGroup) => {
    const dbTable = formGroup.get('dbTable');
    const dbProcedure = formGroup.get('dbStoredProcedure');
    const dbStoredProcedureParameters = formGroup.get('dbStoredProcedureParameters');

    if (
      (dbTable.value === PROCEDURETYPE && !dbProcedure.value) ||
      (dbTable.value === PROCEDURETYPE && !dbStoredProcedureParameters.value.length)
    ) {
      return { invalidProcedure: true };
    }

    return null;
  };
};
