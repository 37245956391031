import { Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { IBucket } from '@core/models/bucket.model';
import { IBucketFile } from '@core/models/bucketFile.model';
import { IDBConnection } from '@core/models/dbconnection.model';
import { ITreeviewItem } from '@core/models/treeview-item.model';
import { ISharepoint } from '@core/models/sharepoint.model';
import { DBTypes } from '@core/models/db-types';
import { IDataSource } from '@core/models/data-source.model';
import { IDatasetBasicInfo } from './dataset-basic-info.model';
import { DatasetTypes } from './dataset-types';
import { procedureValidator } from '@features/db-connection/db-table-manager/procedure-validators';

export abstract class DatasetsAbstractService {
  fileReadySource = new Subject<any>();
  fileReady$ = this.fileReadySource.asObservable();

  cachedDatasources$: Observable<IDataSource[]>;

  MIMETypes = {
    ['xlsx']: 'application/vnd.ms-excel',
    ['pdf']: 'application/pdf',
  };

  formConfig = {
    [DatasetTypes.upload]: (data = this.defaultBasicInfo, file = '') => {
      return {
        ...this.getBasicInfoForm(data),
        ...this.getUploadForm(file),
      };
    },
    [DatasetTypes['db-connect']]: (data = { ...this.defaultDBConnection, ...this.defaultBasicInfo }) => {
      return {
        ...this.getDBConnectionForm(data),
        ...this.getBasicInfoForm(data),
      };
    },
    [DatasetTypes.aggregate]: (data = this.defaultBasicInfo) => {
      return {
        ...this.getAggregationForm(),
        ...this.getBasicInfoForm(data),
      };
    },
  };

  get defaultDBConnection(): IDBConnection {
    return {
      dbType: null,
      dbName: '',
      dbHost: '',
      dbPort: '',
      dbUsername: '',
      dbPassword: '',
      dbTable: '',
      dbWarehouse: '',
      dbRole: '',
      dbSchema: '',
      dbHttpPath: '',
      awsAccessKeyId: '',
      awsSecretAccessKey: '',
      bucketName: '',
      filterPattern: '',
      region: '',
      creationUser: '',
      templateName: '',
      tenantId: '',
      secretValue: '',
      applicationId: '',
      siteId: '',
      driveItemId: '',
      driveItemName: '',
      dbStoredProcedure: '',
      dbStoredProcedureParameters: null,
    };
  }

  get defaultBasicInfo(): IDatasetBasicInfo {
    return {
      description: '',
      name: '',
      image: null,
    };
  }

  addOwned = (datasource: IDataSource, actAs: string[]): IDataSource => {
    return { ...datasource, owned: actAs.includes(datasource.publisher) };
  };

  abstract createDatasource(dataSourceFormdata: FormData): Observable<IDataSource>;

  abstract downloadS3File(bucketFile: IBucketFile): Observable<any>;

  getAggregationForm = () => {
    return {
      source: ['EXCEL'],
      isAggregation: [true, Validators.required],
      datasourceIds: [[]],
      aggregationDatasourceKeys: ['', Validators.required],
    };
  };

  getBasicInfoForm(basicInfo = this.defaultBasicInfo) {
    const { description, name, image } = basicInfo;
    return {
      description: [description],
      name: [name, Validators.required],
      image: [image],
    };
  }

  getUploadForm(file = null) {
    return {
      file: [file, Validators.required],
    };
  }

  getDefaultFields(path = 'Root') {
    return [
      {
        format: '',
        level: 0,
        path: [path],
        type: 'folder',
      },
    ];
  }

  getDatasourcesWithOwner = (datasources: IDataSource[], actAs: string[]): IDataSource[] => {
    return datasources.map(datasource => {
      return this.addOwned(datasource, actAs);
    });
  };

  getDBConnectionForm(dbconn = this.defaultDBConnection) {
    const conf = {};
    Object.keys(dbconn).forEach(key => {
      conf[key] = [dbconn[key]];
    });
    conf['dbTable'] = [{ value: dbconn.dbTable, disabled: true }, Validators.required];
    if (dbconn.dbType) {
      const dbTypeConf = this.getDBTypeConf(dbconn);
      Object.keys(dbTypeConf).forEach(item => {
        conf[item] = dbTypeConf[item];
      });
    }
    return conf;
  }

  getDBTypeConf = (dbConn: IDBConnection) => {
    const { dbType, dbTable, dbStoredProcedure, dbStoredProcedureParameters, siteId, driveItemId, driveItemName } = dbConn;
    let conf;
    if (dbType === DBTypes.Sharepoint) {
      conf = {
        dbTable: [dbTable],
        dbStoredProcedure: [dbStoredProcedure],
        dbStoredProcedureParameters: [dbStoredProcedureParameters],
        siteId: [siteId, Validators.required],
        driveItemId: [driveItemId, Validators.required],
        driveItemName: [driveItemName],
      };
    } else {
      conf = {
        dbTable: [dbTable, Validators.required],
        dbStoredProcedure: [dbStoredProcedure],
        dbStoredProcedureParameters: [dbStoredProcedureParameters],
        siteId: [siteId],
        driveItemId: [driveItemId],
      };
    }

    return conf;
  };

  abstract getDBTables(dbConnection: IDBConnection): Observable<string[]>;

  abstract getDBTableColumns(dbConnection: IDBConnection): Observable<string[]>;

  abstract getS3Files(dbConnection: IDBConnection): Observable<IBucket[]>;

  abstract getFolder(dbConnection: IDBConnection, options): Observable<ITreeviewItem[]>;

  abstract getSites(dbConnection: IDBConnection, options): Observable<ISharepoint[]>;

  abstract getRoot(dbConnection: IDBConnection, options): Observable<ITreeviewItem>;

  abstract getSharepointAuth(dbConnection: IDBConnection, options): Observable<boolean>;

  getValidators = (dbType: DBTypes) => {
    if (dbType === DBTypes.Kx) {
      return [procedureValidator];
    } else {
      return [];
    }
  };

  abstract getFileFields(file: File): Observable<any>;
}
